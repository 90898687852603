const RecommendationHeading = () => {
  return (
    <div className=" w-full flex justify-center">
      <div className="max-w-[20ch]  xs:text-[28px] sm:text-4xl lg:text-5xl text-xl font-pJSSB text-center leading-relaxed tracking-wide  text-[#1D045D] ">
        Examples of lifestyle recommendations Sakhi generates
      </div>
    </div>
  );
};

export default RecommendationHeading;
