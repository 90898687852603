import HowItWorksComponents from "./HowItWorksComponents";

const HowItWorksV3 = () => {
  return (
    <div className="w-full min-h-screen max-w-screen-xl mx-auto relative z-0 ">
      <div className="py-14 ">
        <HowItWorksComponents />
      </div>
    </div>
  );
};

export default HowItWorksV3;
