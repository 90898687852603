const HowItWorksHeading = () => {
  return (
    <div className="py-5 mb-8">
      <div className=" xs:text-[28px] sm:text-4xl lg:text-5xl text-xl font-pJSSB text-center leading-relaxed tracking-wide">
        How It Works ?
      </div>
    </div>
  );
};
export default HowItWorksHeading;
